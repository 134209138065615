@import '../../styles/appvar';

.container {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 100px;
}

.formArea {
  border: 1px solid #ECEDEE;
  width: 508px;
  padding: 54px 60px;
  @media (max-width: @screen-sm-max) {
    border: none;
    width: 100%;
    max-width: 508px;
    padding: 0 24px;
  }
}

.logo {
  margin: 38px 0;
  cursor: pointer;
}

.title {
  font-size: 30px !important;
  line-height: 30px !important;
  font-weight: 700 !important;
  margin: 0 !important;
}

.subTitle {
  font-size: 16px !important;
  line-height: 19px !important;
  color: #5B6272;
  margin-top: 10px;
}

.userCard {
  border: 1px solid #ECEDEE;
  width: 100%;
  padding: 20px;
}

.buttonBar {
  display: flex;
  gap: 12px;
  margin-top: 20px;
  justify-content: stretch;

  > * {
    width: calc(50% - 6px);
  }
}

.para {
  text-align: justify;
}

.socialBtn {
  width: 254px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.signInLink {
  margin-top: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-left: 5px;
  }

  > *:first-child {
    margin-left: unset;
  }
}

.signupcontainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: @app-gap-lg;
}

.forgetPassword {
  color: #009E52;
  font-size: @app-font-size-text5;
  cursor: pointer;
  font-weight: 400;
}

.forgetPassword:hover {
  text-decoration: underline;
}

.signup {
  color: #009E52;
  font-size: @app-font-size-text5;
  font-weight: bold;
  cursor: pointer;
}

.signup:hover {
  text-decoration: underline;
}

.ChangePassword {
  width: 100%;
  display: flex;
  justify-content: center;

  .passwordContainer {
    width: 100%;
    max-width: 600px;
  }
}
